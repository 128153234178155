import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import accessImage from "../feature/access/assets/images/access-map.webp"

const AccessPage = () => {
  return (
    <Layout>
      <h2>アクセス</h2>
      <img src={accessImage} alt=""/>
    </Layout>
  )
}

export const Head = () => <Seo title="Access" />

export default AccessPage
